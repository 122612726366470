import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import spinnerIcon from "../../assets/icons/spinner.svg";
import noDataImg from "../../assets/img/no-data.svg";
import locationIcon from "../../assets/icons/location.svg";
import calendarIcon from "../../assets/icons/calendar-tick.svg";
import UpdateRegistration from "./UpdateRegistration";

const Registered = () => {
  const [tournaments, setTournaments] = useState([]);
  const [currentList, setCurrentList] = useState([]);
  const [loadingTournaments, setLoadingTournaments] = useState(false);
  const [currentFilter, setCurrentFilter] = useState("upcoming");
  const [registered, setRegistered] = useState([]);
  const [pendingReg, setPendingReg] = useState([]);
  const axios = useAxiosPrivate();
  const location = useLocation();
  const [updatePending, setUpdatePending] = useState({ data: {}, show: false });
  const [isRegistered, setIsRegistered] = useState({ id: "", status: false });
  const [isCancel, setIsCancel] = useState({ id: "", status: false });

  useEffect(() => {
    if (isRegistered.status) {
      setRegistered((reg) => [
        ...reg,
        ...pendingReg.filter((ele) => {
          if (ele._id === isRegistered.id) {
            const newEle = ele;
            newEle.teams.status = "paid";
            return newEle;
          }
          return null;
        }),
      ]);
      setPendingReg((reg) => reg.filter((ele) => ele._id !== isRegistered.id));
    }

    if (isCancel.status) {
      setPendingReg((reg) => reg.filter((ele) => ele._id !== isCancel.id));
    }
  }, [isRegistered, isCancel]);

  const fetchRegistered = (filter) => {
    axios
      .get(`/user/tournaments/registered?filter=${filter}`)
      .then((res) => {
        if (res?.data?.success) {
          setRegistered(
            res?.data?.data.filter((value) => value.teams.status === "paid")
          );
          setPendingReg(
            res?.data?.data.filter((value) => value.teams.status === "pending")
          );
        } else {
          setRegistered([]);
        }
      })
      .catch(() => {
        setRegistered([]);
      })
      .finally(() => {
        setLoadingTournaments(false);
      });
  };

  useEffect(() => {
    setLoadingTournaments(true);
    fetchRegistered(currentFilter);
  }, [currentFilter]);

  useEffect(() => {
    setCurrentList(
      registered
    );
  }, [currentFilter, registered]);

  return (
    <div className="">
      <div className="max-w-[1400px] mx-auto px-5 sm:px-10 box-border">
        <div className="mt-3 flex gap-x-1">
          <button
            type="button"
            className={`${
              currentFilter === "upcoming"
                ? "bg-gradient-to-r from-primary-start to-primary-end text-white"
                : "bg-slate-200 text-black"
            } rounded py-2 px-3`}
            onClick={() => {
              setCurrentFilter("upcoming");
            }}
          >
            Upcoming
          </button>
          <button
            type="button"
            className={`${
              currentFilter === "live"
                ? "bg-gradient-to-r from-primary-start to-primary-end text-white"
                : "bg-slate-200 text-black"
            } rounded py-2 px-3`}
            onClick={() => {
              setCurrentFilter("live");
            }}
          >
            Live
          </button>
          <button
            type="button"
            className={`${
              currentFilter === "ended"
                ? "bg-gradient-to-r from-primary-start to-primary-end text-white"
                : "bg-slate-200 text-black"
            } rounded py-2 px-3`}
            onClick={() => {
              setCurrentFilter("ended");
            }}
          >
            Ended
          </button>
        </div>
        {loadingTournaments ? (
          <div className="flex justify-center items-center h-[80vh]">
            <img
              src={spinnerIcon}
              className="w-9 animate-spin"
              alt="Loading..."
            />
          </div>
        ) : (
          <div className="flex gap-y-10 flex-col min-[700px]:flex-row justify-between">
            <div className="w-full min-[700px]:w-[60%] md:w-[65%]">
              {!currentList.length ? (
                <div className="h-[70vh] flex justify-center flex-col items-center">
                  <img
                    src={noDataImg}
                    className="w-52"
                    alt="waiting for approval"
                  />
                  <div className="py-6 px-5 flex flex-wrap justify-center items-center gap-x-2 text-center">
                    {currentFilter === "active" ? (
                      <>
                        <h2 className="text-lg">
                          You don&apos;t have registered tournaments!
                        </h2>
                        <Link to="/explore" className="text-primary text-lg">
                          Explore
                        </Link>
                      </>
                    ) : (
                      <h2 className="text-lg">No tournaments found!</h2>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  {currentList.map((ele) => (
                    <Link to={`/tournament/${ele._id}`} key={ele._id}>
                      <div className="flex bg-white mt-5 border p-4 gap-4 shadow-md rounded-lg duration-500 hover:scale-105 hover:shadow-xl">
                        <div>
                          <img
                            src={ele.cover}
                            alt="profile"
                            className="w-28 h-24 rounded"
                          />
                        </div>

                        <div className="flex flex-col gap-2 flex-grow">
                          <p className="text-xl font-semibold tournament-title">
                            {ele.title}
                          </p>
                          <div className="flex items-center gap-1">
                            <img
                              alt="location"
                              src={locationIcon}
                              className="w-4 h-4"
                            />
                            <span className="text-base">{ele?.location}</span>
                          </div>
                          <div className="flex justify-between items-center w-full">
                            <div className="flex items-center gap-1">
                              <img
                                alt="calendar"
                                src={calendarIcon}
                                className="w-4 h-4"
                              />
                              <span className="text-sm ">
                                {ele?.start_date}
                              </span>
                            </div>
                            <div className="flex flex-col items-end">
                              <p className="text-gray-700 text-sm sm:text-base">
                                {ele.teams_count || 0}
                                <span className="text-gray-500 ml-1">
                                  registered
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <div className="w-full min-[700px]:w-[35%] md:w-[30%]">
              {pendingReg && pendingReg.length > 0 && (
                <div className="bg-white p-6 shadow-lg border rounded-lg my-4">
                  <h1>Pending :</h1>
                  <hr />
                  {pendingReg.map((ele) => (
                    <div
                      key={ele._id}
                      onClick={() => {
                        setUpdatePending({ data: ele, show: true });
                      }}
                      className="flex cursor-pointer gap-x-2 px-2 items-center rounded-md mt-1 hover:bg-gradient-to-r hover:from-slate-200 hover:to-slate-50 box-border"
                    >
                      <img
                        src={ele.cover}
                        className="rounded-full w-8 h-8"
                        alt="s"
                      />
                      <div className="py-2">
                        <p className="text-sm sm:text-base overflow-hidden text-ellipsis tournament-title">
                          {ele.title}
                        </p>
                        <p className="-mt-1 text-slate-400 text-sm">
                          Last date : {ele.registration.last_date}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <UpdateRegistration
        openState={updatePending?.show}
        setIsRegistered={(data) => {
          setIsRegistered(data);
        }}
        isCancel={(data) => {
          setIsCancel(data);
        }}
        data={updatePending?.data}
        close={() => {
          setUpdatePending({ data: {}, show: false });
        }}
      />
    </div>
  );
};

export default Registered;
