import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required("Enter you email")
    .email("Enter a valid email"),
  password: yup
    .string()
    .trim()
    .required("Password can not be empty")
});

export const signupSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required("Name can not be empty")
    .test("isPerfectString", "Enter a valid name", (arg) =>
      /^[A-Za-z ]+$/.test(arg)
    ),
  email: yup
    .string()
    .trim()
    .required("Enter you email")
    .email("Enter a valid email"),
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{8,16}$/,
      "Password must be 8-16 chars with uppercase, lowercase, number, and symbol"
    ),
});

export const resetPassSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W]).{8,16}$/,
      "Password must be 8-16 chars with uppercase, lowercase, number, and symbol"
    ),
  confirm_password: yup
    .string()
    .trim()
    .required("Confirm password can't be empty")
    .test("passwords-match", "Passwords must match", function check(value) {
      return this.parent.password === value;
    }),
});
