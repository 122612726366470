import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { getMatches } from "../../helpers/apis/guest";
import BackgroundImage from "../../assets/img/football-background.png";
import Team1 from "../../assets/img/Team1.png";

function LiveMatch() {
  const [cards, setCards] = useState([]);
  const [visibleCards, setVisibleCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0); // Track current index for pagination
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    getMatches()
      .then((res) => {
        if (res.data.success) {
          const allMatches = res?.data?.data;
          setCards(allMatches);
          setVisibleCards(allMatches.slice(0, 3)); // Display only the first 3 matches initially
        } else {
          setCards([]);
          setVisibleCards([]);
        }
      })
      .catch(() => {
        setCards([]);
        setVisibleCards([]);
      });
  }, [auth]);

  return (

    < div className="relative box-border" >
      {/* <div className="flex flex-col py-10 px-5 sm:p-10 mx-auto">
          <h3 className="text-2xl text-center sm:text-left font-bold mb-6">
            Upcoming Live Matches
          </h3>
          <div className="my-8">
            <div className="flex flex-col sm:flex-row justify-between items-center sm:items-start flex-grow w-full sm:w-2/3 mx-auto gap-6">
              <div className="flex flex-col sm:flex-row gap-y-4 gap-x-4 sm:gap-x-6 items-center">
                <img
                  src={Team1}
                  className="w-16 sm:w-20 h-16 sm:h-20 rounded-full"
                  alt=".."
                />
                <h1 className="text-left text-lg sm:text-xl font-bold">
                  <span className="">Team A</span>
                </h1>
              </div>
              <div className="flex flex-col items-center gap-2 sm:gap-4">
                <div className="flex gap-x-2 justify-center items-center">
                  <h1 className="text-3xl sm:text-4xl font-medium">--</h1>
                  <h1 className="text-slate-400 text-3xl sm:text-4xl font-medium">
                    /
                  </h1>
                  <h1 className="text-3xl sm:text-4xl font-medium">--</h1>
                </div>
                <div className="sm:flex sm:flex-col sm:items-center hidden sm:block">
                  <h1 className="font-semibold text-xs sm:text-base text-slate-400">
                    June 04 2024, Kalyana Nagar
                  </h1>
                  <h1 className="font-semibold text-xs sm:text-base">
                    5.00pm Onwards
                  </h1>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-y-4 gap-x-4 sm:gap-x-6 items-center">
                <h1 className="text-right text-lg sm:text-xl font-bold">
                  <span className="">Team B</span>
                </h1>
                <img
                  src={Team1}
                  className="w-16 sm:w-20 h-16 sm:h-20 rounded-full"
                  alt=".."
                />
              </div>
              <div className="flex flex-col items-center sm:hidden block">
                <h1 className="font-semibold text-xs sm:text-base text-slate-400">
                  June 04 2024, Kalyana Nagar
                </h1>
                <h1 className="font-semibold text-xs sm:text-base">
                  5.00pm Onwards
                </h1>
              </div>
            </div>
          </div>
        </div> */}
     <div className="bg-white/60">
  <div className="flex flex-col p-4 sm:p-10 mx-auto">
    <h3 className="text-lg sm:text-xl font-bold mb-6">Upcoming Live Matches</h3>
    <div className="overflow-x-auto scrollbar-hide">
      <div className="flex flex-row gap-4 pb-4" style={{ width: 'max-content' }}>
        {cards.map((matchData) => (
          <div
            key={matchData._id}
            className="flex-shrink-0 w-80 flex flex-col items-center gap-4 bg-white border shadow-md rounded-lg p-4 hover:shadow-2xl focus:shadow-2xl transition-transform duration-200 transform hover:-translate-y-1 focus:-translate-y-1"
          >
            <div className="flex justify-between items-start flex-grow w-full">
              <div className="flex flex-col gap-2 items-center">
                <img
                  src={matchData.match.teamA.profile}
                  className="w-12 h-12 rounded-full"
                  alt={matchData.match.teamA.name}
                />
                <h1 className="text-left font-medium text-sm sm:text-base">
                  {matchData.match.teamA.name}
                </h1>
              </div>
              <div className="flex flex-col items-center gap-4 mt-2">
                <div className="flex gap-x-2 justify-center items-center">
                  <h1 className="text-2xl font-medium">vs</h1>
                </div>
                <div className="flex flex-col items-center">
                  <h1 className="font-semibold text-xs sm:text-sm">
                    {matchData.location}
                  </h1>
                </div>
              </div>
              <div className="flex flex-col gap-2 items-center">
                <img
                  src={matchData.match.teamB.profile}
                  className="w-12 h-12 rounded-full"
                  alt={matchData.match.teamB.name}
                />
                <h1 className="text-right font-medium text-sm sm:text-base">
                  {matchData.match.teamB.name}
                </h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</div>

    </div >
  );
}

export default LiveMatch;
