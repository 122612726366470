import React from 'react';
import { useParams, Navigate } from 'react-router-dom';
import CreateStaff from '../../components/CreateStaff/CreateStaff';
import { useSelector } from 'react-redux';

function StaffPage() {
  const { id } = useParams(); // Get the id from URL parameters
  const auth = useSelector((state) => state.auth); // Get auth state


  // Redirect if the club status is not active
  if (auth?.clubStatus !== 'active') {
    return <Navigate to="/user/club" replace />;
  }


  return <CreateStaff id={id} />;
}

export default StaffPage;
