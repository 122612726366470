import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  FormControl,
  FormHelperText,
} from "@mui/material";

function StaffPermission({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) {
  const defaultPermissions = [
    {
      title: "playerRegistration",
      displayName: "Player Registration",
      type: "",
      enabled: false,
    },
    {
      title: "tournament",
      displayName: "Tournament",
      type: "",
      enabled: false,
    },
    {
      title: "staffManagement",
      displayName: "Staff Management",
      type: "",
      enabled: false,
    },
    // Add more permissions here as needed
  ];

  useEffect(() => {
    if (!values.access || values.access.length === 0) {
      setFieldValue("access", defaultPermissions);
    } else {
      const newPermissions = defaultPermissions.filter((defaultPermission) => 
        !values.access.some((perm) => perm.title === defaultPermission.title)
      );
      if (newPermissions.length > 0) {
        setFieldValue("access", [...values.access, ...newPermissions]);
      }
    }
  }, [values.access, setFieldValue]);

  const handleSelectChange = (index) => (event) => {
    setFieldValue(`access[${index}].type`, event.target.value);
  };

  const handleSwitchChange = (index) => (event) => {
    setFieldValue(`access[${index}].enabled`, event.target.checked);
    if (event.target.checked && !values.access[index].type) {
      setFieldValue(`access[${index}].type`, "Read Only");
    }
  };

  const renderErrorMessages = (error) => {
    if (typeof error === "string") {
      return error;
    } else if (typeof error === "object" && error !== null) {
      return Object.values(error).join(", ");
    }
    return null;
  };

  return (
    <div className="bg-white border shadow-2xl rounded-lg p-6 sm:p-10">
      <div className="w-full sm:w-[80vw] max-w-[700px] flex flex-col gap-y-2 box-border mx-auto">
        <div className="text-2xl font-medium">Staff Permission</div>
        <div>
          <table className="table-auto border-spacing-8 w-full text-left">
            <thead className="text-slate-500">
              <tr className="border-b border-slate-300">
                <th className="cursor-pointer font-medium py-2 px-2">Title</th>
                <th className="cursor-pointer font-medium py-2 px-2">
                  Permission Type
                </th>
                <th className="cursor-pointer font-medium py-2 px-2">
                  Permission
                </th>
              </tr>
            </thead>
            <tbody>
              {values.access &&
                values.access.map((permission, index) => (
                  <tr className="border-b border-slate-300" key={index}>
                    <td className="px-2 py-2 whitespace-nowrap text-sm overflow-hidden text-ellipsis">
                      {permission.displayName}
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-sm overflow-hidden text-ellipsis">
                      <FormControl
                        fullWidth
                        error={
                          Boolean(
                            errors.access &&
                              errors.access[index] &&
                              errors.access[index].type
                          )
                        }
                      >
                        <InputLabel id={`select-label-${index}`}>
                          Access Type
                        </InputLabel>
                        <Select
                          labelId={`select-label-${index}`}
                          name={`access[${index}].type`}
                          value={permission.type}
                          onChange={handleSelectChange(index)}
                          onBlur={handleBlur}
                          label="Access Type"
                        >
                          <MenuItem value="Read Only">Read Only</MenuItem>
                          <MenuItem value="Write Only">Write Only</MenuItem>
                          <MenuItem value="Read & Write">Read & Write</MenuItem>
                        </Select>
                        {errors.access &&
                          errors.access[index] &&
                          errors.access[index].type && (
                            <FormHelperText>
                              {renderErrorMessages(errors.access[index].type)}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-sm overflow-hidden text-ellipsis min-w-[150px]">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={permission.enabled}
                            onChange={handleSwitchChange(index)}
                            onBlur={handleBlur}
                          />
                        }
                        key={index}
                      />
                      {errors.access &&
                        errors.access[index] &&
                        errors.access[index].enabled && (
                          <div className="text-red-500 text-sm">
                            {renderErrorMessages(errors.access[index].enabled)}
                          </div>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              {Array.isArray(errors.access) && errors.access.length > 0 && (
                <tr>
                  <td colSpan="3" className="text-red-500 text-center py-2">
                    {errors.access.map((error, index) => (
                      <div key={index}>{renderErrorMessages(error)}</div>
                    ))}
                  </td>
                </tr>
              )}
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

StaffPermission.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default StaffPermission;
