import React from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { AiOutlineClose } from "react-icons/ai";

function CenterPopup({ title, children, openState, close }) {
  return (
    <Dialog
      open={openState}
      onClose={close}
      aria-labelledby="center-popup-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="center-popup-title" sx={{ m: 0, p: 2 }}>
        <div className="text-primary">{title}</div>
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <AiOutlineClose />
        </IconButton>
      </DialogTitle>
      <DialogContent className="overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200" dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
}

CenterPopup.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  openState: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default CenterPopup;
