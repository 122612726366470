/* eslint-disable import/no-anonymous-default-export */

import * as yup from "yup";

const accessItemSchema = yup.object().shape({
  title: yup.string().when('enabled', {
    is: true,
    then: yup.string().required("Title is required when permission is enabled")
  }),
  displayName: yup.string().when('enabled', {
    is: true,
    then: yup.string().required("Display name is required when permission is enabled")
  }),
  type: yup.string().when('enabled', {
    is: true,
    then: yup
      .string()
      .oneOf(
        ["Read Only", "Write Only", "Read & Write"],
        "Invalid permission type"
      )
      .required("Permission type is required when permission is enabled")
  }),
  enabled: yup.boolean(),
});

export default [
  // Step 1
  yup.object().shape({
    // name: yup.string().required('Staff Name is required'),
    dob: yup.date().required("Date of Birth is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: yup
      .string()
      .matches(/^\d+$/, "Phone number must be digits")
      .required("Contact No is required"),
    address1: yup.string().required("Address 1 is required"),
    address2: yup.string().required("Address 2 is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    country: yup.string().required("Country is required"),
    pincode: yup
      .string()
      .matches(/^\d+$/, "Pincode must be digits")
      .required("Pincode is required"),
    role: yup.string().required("Role is required"),
    joining_date: yup.date().required("Date of joining is required"),
  }),
  // Step 2
  yup.object().shape({
    access: yup
      .array()
      .of(accessItemSchema)
      .test(
        "at-least-one-enabled",
        "At least one permission must be selected and enabled",
        (access) => access.some((item) => item.enabled && item.title && item.displayName && item.type)
      )
  }),
];
