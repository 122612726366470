import { useDispatch } from "react-redux";
import { clearAuth } from "../app/slices/authSlice";
import { clearClubData } from "../app/slices/clubSlice";
import { logoutApi } from "../helpers/apis/auth";
import { useNavigate } from "react-router-dom";

function useLogout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    dispatch(clearAuth());
    dispatch(clearClubData());
    logoutApi();
    navigate('/');
  };

  return logout;
}

export default useLogout;
