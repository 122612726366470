import React, { useState } from "react";
import PropTypes from "prop-types";
import TeamData from "./TeamData";
import { Tooltip } from "@mui/material";


function TableTab({ data }) {
  const [showTeamData, setShowTeamData] = useState({
    teamId: "",
    show: false,
    tournament: data._id,
    isHost: data?.isHost,
  });

  const renderRoundRobinTable = (group) => (
    <div className="my-5" key={group.name}>
      <h1 className="font-semibold text-gray-700 mb-3">
        {data.tournament_type === "t1" ? "Score Table" : group?.name}
      </h1>
      <div className="overflow-x-auto">
        <table className="table-auto w-full bg-white rounded-lg shadow-md">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <Tooltip title="Position" placement="top">
                <th className="w-9 p-2 text-center">#</th>
              </Tooltip>
              <Tooltip title="Team Name" placement="top">
                <th className="w-20 p-2 text-left">Team</th>
              </Tooltip>
              <Tooltip title="Matches Played" placement="top">
                <th className="w-9 p-2 text-center">MP</th>
              </Tooltip>
              <Tooltip title="Wins" placement="top">
                <th className="w-9 p-2 text-center">W</th>
              </Tooltip>
              <Tooltip title="Draws" placement="top">
                <th className="w-9 p-2 text-center">D</th>
              </Tooltip>
              <Tooltip title="Losses" placement="top">
                <th className="w-9 p-2 text-center">L</th>
              </Tooltip>
              <Tooltip title="Goals For" placement="top">
                <th className="w-9 p-2 text-center">GF</th>
              </Tooltip>
              <Tooltip title="Goals Against" placement="top">
                <th className="w-9 p-2 text-center">GA</th>
              </Tooltip>
              <Tooltip title="Goal Difference" placement="top">
                <th className="w-9 p-2 text-center">GD</th>
              </Tooltip>
              <Tooltip title="Points" placement="top">
                <th className="w-9 p-2 text-center">PTS</th>
              </Tooltip>
            </tr>
          </thead>
          <tbody>
            {group?.teams
              ?.sort((a, b) => b.pts - a.pts) // Sorting by pts in descending order
              .map((team, index) => (
                <tr
                  key={team.club}
                  onClick={() => {
                    setShowTeamData((prvs) => ({
                      ...prvs,
                      teamId: team.club,
                      show: true,
                    }));
                  }}
                  className="hover:bg-gradient-to-r from-gray-200 to-gray-100 cursor-pointer border-b transition duration-150 ease-in-out"
                >
                  <td className="p-2 text-center">{index + 1}</td>
                  <td className="p-2">
                    <div className="flex items-center gap-x-2">
                      <img
                        src={team.profile}
                        className="rounded-full w-7 h-7"
                        alt="team"
                      />
                      <p className="text-sm sm:text-base overflow-hidden text-ellipsis tournament-title">
                        {team.name}
                      </p>
                    </div>
                  </td>
                  <td className="p-2 text-center">{team.mp}</td>
                  <td className="p-2 text-center">{team.w}</td>
                  <td className="p-2 text-center">{team.d}</td>
                  <td className="p-2 text-center">{team.l}</td>
                  <td className="p-2 text-center">{team.gf}</td>
                  <td className="p-2 text-center">{team.ga}</td>
                  <td className="p-2 text-center">{team.gd}</td>
                  <td className="p-2 text-center">{team.pts}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const renderKnockoutStage = (rounds) => (
    <div className="my-5">
      <h1 className="font-semibold text-gray-700 mb-3">Knockout Stage</h1>
      <div className="overflow-x-auto">
        {rounds.map((round, index) => (
          <div key={index} className="my-3">
            <h2 className="font-semibold text-gray-600">Round {index + 1}</h2>
            <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 lg:grid-cols-4">
              {round.matches.map((match) => (
                <div
                  key={match.id}
                  className="border p-3 rounded-lg shadow-md bg-white"
                >
                  <div className="flex justify-between">
                    <span>{match.team1}</span>
                    <span>{match.score1}</span>
                  </div>
                  <div className="flex justify-between">
                    <span>{match.team2}</span>
                    <span>{match.score2}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      {(data.tournament_type === "t3" || data.tournament_type === "t1")
        ? data?.groups?.length &&
          data?.groups.map((group) => renderRoundRobinTable(group))
        : data?.knockout?.rounds?.length &&
          renderKnockoutStage(data.knockout.rounds)}
      <TeamData
        openState={showTeamData?.show}
        data={showTeamData}
        close={() => {
          setShowTeamData((prvs) => ({ ...prvs, teamId: "", show: false }));
        }}
      />
    </div>
  );
}

TableTab.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TableTab;
