import React from "react";

import Hero from "../../components/Hero/Hero";
import UpcomingSection from "./UpcomingSection";
import QuickButtons from "./QuickButtons";
import CtaOrganize from "./CtaOrganize";
import aboutImg from "../../assets/img/about.svg";
import LiveMatch from "./LiveMatch";

function Home() {
  return (
    <>
      <Hero />
      <UpcomingSection />
      <LiveMatch/>
    </>
  );
}

export default Home;
