import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import spinnerIcon from "../../assets/icons/spinner.svg";
import arrowDownIcon from "../../assets/icons/arrow-down.svg";
import InputFields from "../../components/InputFields/InputFields";
import { getTournaments } from "../../helpers/apis/guest";
import Carousel from "./Carousel";
import TournamentDashboardCard from "../../components/Cards/TournamentDashboardCard";

function Explore() {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState({ all: true, upcoming: true, live: true });
  const [data, setData] = useState({ all: [], upcoming: [], live: [] });
  const [loadingMore, setLoadingMore] = useState({ all: false, upcoming: false, live: false });
  const [currentPage, setCurrentPage] = useState({ all: 1, upcoming: 1, live: 1 });
  const [totalPage, setTotalPage] = useState({ all: 1, upcoming: 1, live: 1 });
  const [search, setSearch] = useState("");

  const fetchData = async (filter, page = 1, searchQuery = "") => {
    setLoadingMore((prev) => ({ ...prev, [filter]: true }));
    
    try {
      const query = `&limit=9&filter=${filter}&page=${page}&search=${searchQuery}`;
      const res = await getTournaments(query, auth);
      
      if (res?.data?.success) {
        const fetchedData = res.data.data.tournaments;
        const totalRec = res?.data?.data?.total || 1;
        const limit = res?.data?.data?.limit || 1;
        const totalPageCount = Math.ceil(totalRec / limit);

        return {
          data: fetchedData,
          totalPage: totalPageCount,
          currentPage: page
        };
      }
    } catch (error) {
      console.error(`Error fetching ${filter} tournaments:`, error);
    } finally {
      setLoadingMore((prev) => ({ ...prev, [filter]: false }));
    }

    return null;
  };

  const updateData = async (filter, page = 1, searchQuery = "") => {
    setLoading((prev) => ({ ...prev, [filter]: true }));
    
    const result = await fetchData(filter, page, searchQuery);
    
    if (result) {
      setData((prevData) => ({
        ...prevData,
        [filter]: page === 1 ? result.data : [...prevData[filter], ...result.data],
      }));
      setTotalPage((prevTotalPage) => ({
        ...prevTotalPage,
        [filter]: result.totalPage,
      }));
      setCurrentPage((prevCurrentPage) => ({
        ...prevCurrentPage,
        [filter]: result.currentPage,
      }));
    }
    
    setLoading((prev) => ({ ...prev, [filter]: false }));
  };

  useEffect(() => {
    updateData("all");
    updateData("upcoming");
    updateData("live");
  }, []);

  useEffect(() => {
    if (search.length >= 2) {
      updateData("all", 1, search);
      updateData("upcoming", 1, search);
      updateData("live", 1, search);
    } else if (search.length === 0) {
      updateData("all");
      updateData("upcoming");
      updateData("live");
    }
  }, [search]);

  const handleLoadMore = (filter) => {
    updateData(filter, currentPage[filter] + 1, search);
  };

  const renderSection = (title, filter) => (
    <div className="my-8">
      <div className="text-2xl font-semibold">{title}</div>
      <div className="relative">
        {loading[filter] ? (
          <div className="flex justify-center items-center h-[40vh]">
            <img src={spinnerIcon} className="w-9 animate-spin" alt="Loading..." />
          </div>
        ) : (
          <>
            {data[filter] && data[filter].length > 0 ? (
              <>
                <div className="mt-4 grid grid-cols-1 min-[580px]:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 auto-rows-auto gap-4">
                  {data[filter].map((ele) => (
                    <TournamentDashboardCard data={ele} key={ele._id} />
                  ))}
                </div>
                {totalPage[filter] > currentPage[filter] && (
                  <div className="flex justify-center items-end mt-6">
                    <button
                      type="button"
                      className="bg-slate-200 disabled:bg-slate-100 disabled:text-gray-500 cursor-pointer w-fit flex items-center gap-x-3 px-4 py-1 rounded-full"
                      disabled={loadingMore[filter]}
                      onClick={() => handleLoadMore(filter)}
                    >
                      <p>Load more</p>
                      {loadingMore[filter] ? (
                        <img src={spinnerIcon} alt="more" className="w-5 animate-spin" />
                      ) : (
                        <img src={arrowDownIcon} alt="more" className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-4 text-gray-500">No tournaments</div>
            )}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col py-10 px-5 sm:p-10 max-w-[1400px] bg-[#F8F9FA] mx-auto box-border">
      <div className="relative rounded bg-slate-100 h-60 w-[100%] m-auto">
        <Carousel />
      </div>
      <div className="mt-8 flex justify-between flex-wrap mt-12">
        <div>
          <InputFields
            holder="Search"
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            transform="w-full sm:w-80"
            className="w-full"
          />
        </div>
      </div>
      {renderSection("Upcoming Tournaments", "upcoming")}
      {renderSection("Live Tournaments", "live")}
      {renderSection("All Tournaments", "all")}
    </div>
  );
}

export default Explore;