/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import otpSchema from "../../schema/otp";
import { resendOtpApi, verifyEmailApi } from "../../helpers/apis/auth";
import { setAuth } from "../../app/slices/authSlice";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";

function OtpForm() {
  const fields = ["otp1", "otp2", "otp3", "otp4", "otp5", "otp6"];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const confirmToken = location.state?.confirmToken;
  const from = location.state?.from;
  const Ref = useRef(null);
  const [timer, setTimer] = useState("01:30");
  const [showResendBt, setShowResendBt] = useState(false);
  const [token, setToken] = useState(confirmToken);

  const inputRefs = useRef([]);

  const getDeadTime = () => {
    const deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 90);
    return deadline;
  };

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return { total, minutes, seconds };
  };

  const startTimer = (e) => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total === 0) setShowResendBt(true);
    else if (total >= 0 && showResendBt) setShowResendBt(false);
    if (total >= 0) {
      setTimer(
        `${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`
        }`
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("01:30");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const handleResendOtp = () => {
    clearTimer(getDeadTime());
    resendOtpApi({ token })
      .then((res) => {
        if (res.data.success) {
          useSuccessToast({ message: res.data?.message });
          navigate(".", {
            replace: true,
            state: { confirmToken: res.data.data.confirmToken, from },
          });
          setToken(res.data.data.confirmToken);
        } else {
          useErrorToast({
            message: res.data.message || "Something went wrong",
          });
        }
      })
      .catch((err) => {
        useErrorToast({
          message: err?.response?.data?.message || "Something went wrong",
        });
      });
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  const formik = useFormik({
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    },
    validationSchema: otpSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const otp = Object.values(values).join("");
      verifyEmailApi({ otp, token })
        .then((res) => {
          resetForm({ values: "" });
          if (res.data.success) {
            dispatch(setAuth(res.data.data));
            navigate(location.state?.from || "/", {
              replace: true,
              state: {
                confirmToken: "",
              },
            });
          } else {
            useErrorToast({ message: res.data.message });
          }
        })
        .catch((err) => {
          useErrorToast({
            message: err?.response?.data?.message || "Something went wrong",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").replace(/\s+/g, "");
    if (pastedData.length === fields.length) {
      fields.forEach((field, index) => {
        formik.setFieldValue(field, pastedData[index]);
      });
      const lastInput = inputRefs.current[fields.length - 1];
      if (lastInput && typeof lastInput.focus === "function") {
        lastInput.focus();
      }
    }
  };

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value) && value.length <= 1) {
      formik.setFieldValue(fields[index], value);

      if (value && index < fields.length - 1) {
        const nextInput = inputRefs.current[index + 1];
        if (nextInput && typeof nextInput.focus === "function") {
          nextInput.focus();
        }
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      if (formik.values[fields[index]]) {
        formik.setFieldValue(fields[index], "");
      } else if (index > 0) {
        const prevInput = inputRefs.current[index - 1];
        if (prevInput && typeof prevInput.focus === "function") {
          prevInput.focus();
          formik.setFieldValue(fields[index - 1], "");
        }
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      const prevInput = inputRefs.current[index - 1];
      if (prevInput && typeof prevInput.focus === "function") {
        prevInput.focus();
      }
    } else if (e.key === "ArrowRight" && index < fields.length - 1) {
      const nextInput = inputRefs.current[index + 1];
      if (nextInput && typeof nextInput.focus === "function") {
        nextInput.focus();
      }
    }
  };

  useEffect(() => {
    const firstInput = inputRefs.current[0];
    if (firstInput && typeof firstInput.focus === "function") {
      firstInput.focus();
    }
  }, []);

  return (
    <form className="w-73 mx-auto" onPaste={handlePaste}>
      <div className="flex gap-x-4 w-full mb-5">
        {fields.map((ele, index) => (
          <input
            key={ele}
            name={ele}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            maxLength="1"
            value={formik.values[ele]}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            onBlur={formik.handleBlur}
            ref={(el) => (inputRefs.current[index] = el)}
            className="border p-2 text-center w-12"
          />
        ))}
      </div>
      <button
        type="submit"
        className="w-full bg-orange-500 text-white p-2 rounded"
        onClick={formik.handleSubmit}
      >
        {loading ? "Submitting..." : "Verify"}
      </button>
      {!showResendBt ? (
        <p className="my-5 text-center">{timer}</p>
      ) : (
        <p
          className="my-5 cursor-pointer hover:text-primary text-center"
          onClick={handleResendOtp}
        >
          Resend OTP
        </p>
      )}
    </form>
  );
}

export default OtpForm;
