import React, { useRef } from "react";
import PropTypes from "prop-types";

import InputFields, { CssTextField } from "../InputFields/InputFields";
import cameraIcon from "../../assets/icons/camera.svg";

function Details({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  setFieldValue,
}) {
  const ref = useRef();

  const handleUploadImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setFieldValue("cover.file", image);
      setFieldValue("cover.url", URL.createObjectURL(image));
    }
  };

  return (
    <div className="bg-white border shadow-2xl rounded-lg p-6 sm:p-10">
      <div className="w-full sm:w-[80vw] max-w-[700px] flex flex-col gap-y-2 box-border mx-auto">
        <div className="flex items-center flex-col">
          <input
            type="file"
            name="cover.url"
            ref={ref}
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleUploadImage}
            onBlur={handleBlur}
          />
          <button
            type="button"
            onClick={() => {
              ref.current.click();
            }}
            className={`relative w-24 h-24 border-2 rounded-full ${
              errors.cover?.url && touched.cover?.url && "border-red-500"
            }`}
          >
            <div
              className="relative w-full h-full rounded-full flex justify-center items-center bg-cover bg-center"
              style={{
                backgroundImage: `url('${values.cover?.url}')`,
              }}
            >
              <img
                src={cameraIcon}
                alt="Camera Icon"
                className="absolute w-8 h-8"
              />
            </div>
          </button>
          <div className="text-sm font-light mt-2 text-center">
            Upload Tournament Photo
          </div>
        </div>
        <CssTextField
          error={errors.title && touched.title}
          sx={{ marginTop: "8px" }}
          label="Title of tournament"
          name="title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={errors.title && touched.title ? errors.title : ""}
          fullWidth
        />
        <CssTextField
          error={errors.short_description && touched.short_description}
          sx={{ marginTop: "8px" }}
          name="short_description"
          label="Brief description"
          value={values.short_description}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors.short_description && touched.short_description
              ? errors.short_description
              : ""
          }
          multiline
          row={3}
        />
        <CssTextField
          error={errors.description && touched.description}
          sx={{ marginTop: "8px" }}
          name="description"
          label="About the tournament"
          rows={8}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors.description && touched.description ? errors.description : ""
          }
          multiline
          fullWidth
        />
        <div className="flex flex-col sm:flex-row gap-4 mb-1 mt-6 w-full">
          <div className="flex flex-col w-full">
            <p className="text-gray-700 sm:-mt-3 text-sm">Starting date</p>
            <InputFields
              name="start_date"
              className="h-14"
              holder="Starting date"
              value={values.start_date}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={
                errors.start_date && touched.start_date ? errors.start_date : ""
              }
              type="date"
              fullWidth
            />
          </div>
          <CssTextField
            error={errors.city && touched.city}
            sx={{ marginTop: "8px" }}
            name="city"
            label="City"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errors.city && touched.city ? errors.city : ""
            }
            fullWidth
          />
        </div>
        <CssTextField
          error={errors.location && touched.location}
          sx={{ marginTop: "8px" }}
          name="location"
          label="Location"
          rows={8}
          value={values.location}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={
            errors.location && touched.location ? errors.location : ""
          }
          multiline
          fullWidth
        />
      </div>
    </div>
  );
}

Details.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default Details;
