/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import MultiStepForm from "../MultiStepForm/MultiStepForm";
import staffValidation from "../../schema/user/staffSchema";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";
import StaffDetails from "./StaffDetails";
import StaffPermission from "./StaffPermission";
import { formatDate } from "../../helpers/formatDate";

function CreateStaff({ data, id }) {
  const [initialData, setInitialData] = useState(data);
  const [isLastStep, setIsLastStep] = useState(false);
  const auth = useSelector((state) => state.auth);
  const axios = useAxiosPrivate();
  const navigate = useNavigate();
  const isEdit = id!=='new';
  useEffect(() => {
    if (isEdit) {
      axios.get(`/staff/${id}`).then(response => {
        const staffData = response.data.data;

        // Convert dates from ISO string to dd/mm/yyyy
        const formattedData = {
          ...staffData,
          dob: formatDate(staffData.dob),
          joining_date: formatDate(staffData.joining_date),
        };


        setInitialData(formattedData);
      }).catch(error => {
        useErrorToast({
          message: error?.response?.data?.message || "Failed to fetch staff details",
        });
      });
    } else {
      setInitialData(data);
    }
  }, [id,isLastStep]);

  const renderStep = (currentStep, props, isEdit) => {
    setIsLastStep(currentStep === 1);
    switch (currentStep) {
      case 0:
        return <StaffDetails {...props} isEdit={isEdit} />;
      case 1:
        return <StaffPermission {...props} />;
      default:
        return null;
    }
  };


  const handleSubmit = (values) => {
    const url = id !== 'new' ? `/staff/edit/${id}` : "/staff/register";
  
    if (values.cover?.file) {
      // create a FormData
      const formData = new FormData();
      formData.append("club", auth.club);
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
  
      return axios.put(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
  
    return axios.put(url, {
      ...values,
      clubId: auth.club
    });
  };
  


  const submitForm = async (values, currentStep, actions) => {
    const formData = { ...values, step: currentStep + 1 };
    try {
      const res = await handleSubmit(formData);

      if (isLastStep) navigate("/user/club");

      actions.setFieldValue("id", res.data?.data?.id);

      setInitialData((prev) => ({
        ...prev,
        id: res.data?.data?.id,
      }));

      useSuccessToast({
        message: res?.data?.message || (isEdit ? "Staff updated" : "Staff enrolled"),
      });
    } catch (err) {
      useErrorToast({
        message: err?.response?.data?.message || "Something went wrong",
      });
      throw new Error();
    }
  };

  const stepsLabel = ["Staff Details", "Permission"];

  return !auth?.club ? (
    <Navigate to="/user/club" replace />
  ) : (
    <div className="px-5 sm:px-10 py-12">
      <MultiStepForm
        reInitialize={isEdit}
        formInitialValues={initialData}
        validationSchema={staffValidation}
        submitForm={submitForm}
        isLastStep={isLastStep}
        stepsLabel={stepsLabel}
        totalStep={2}
      >
        {(currentStep, { ...props }) => renderStep(currentStep, props,isEdit)}
      </MultiStepForm>
    </div>
  );
}

CreateStaff.defaultProps = {
  data: {
    id: "",
    name: "",
    dob: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    role: "",
    joining_date: "",
  },
  isEdit: false, // Default to false if not provided
  id: 'new', // Default to 'new' if not provided
};

CreateStaff.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    dob: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    pincode: PropTypes.string,
    role: PropTypes.string,
    joining_date: PropTypes.string,
  }),
  isEdit: PropTypes.bool, // Added isEdit to prop types
  id: PropTypes.string, // Added id to prop types
};

export default CreateStaff;
