import React, { useRef } from "react";
import PropTypes from "prop-types";
import MatchData from "./LiveTournament/MatchData";
import { MdOutlineLocationOn } from "react-icons/md";

function MatchTab({ data, showMatchData, setShowMatchData }) {
  const ref = useRef();
  return (
    <div className="mb-10 mt-6">
      <div className="overflow-auto">
        {data?.matches?.rounds
          .sort((a, b) => a.round_no - b.round_no)
          .map((round) => (
            <div className="mb-4 mx-2 sm:mx-12" key={round.round_no}>
              <h1 className="font-semibold text-slate-400/50 text-sm sm:text-base">{round?.name}</h1>
              {round?.matches
                ?.sort((a, b) => a.match_no - b.match_no)
                .map((match) => (
                  <div
                    className="bg-white border shadow-md rounded-lg p-4 sm:p-6 my-4 cursor-pointer"
                    onClick={() => {
                      if (match?.teamA?.club && match?.teamB?.club)
                        setShowMatchData((prvs) => ({
                          ...prvs,
                          show: true,
                          match,
                          round: {
                            round_no: round?.round_no,
                            round_name: round?.name,
                          },
                        }));
                    }}
                    key={match.match_no}
                    ref={
                      data?.matches?.c_match[0] ===
                        parseInt(round?.round_no, 10) + 1 &&
                        data?.matches?.c_match[1] ===
                        parseInt(match?.match_no, 10) + 1
                        ? ref
                        : null
                    }
                  >
                    <div className="flex flex-col sm:flex-row justify-between gap-4 sm:gap-6 px-2 sm:px-6 mb-2 sm:items-center">
                      <div className="flex flex-grow justify-between items-center">
                        <div className="flex gap-x-2 flex-col sm:flex-row sm:gap-x-4 items-center">
                          <img
                            src={
                              match?.teamA?.profile ||
                              "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"
                            }
                            className="w-12 h-12 sm:w-16 sm:h-16 rounded-full"
                            alt=".."
                          />
                          <h1 className="text-left text-sm sm:text-lg font-medium w-32 truncate">
                            {match?.teamA?.name ? (
                              match.teamA.name
                            ) : (
                              <span className="text-slate-500">Team A</span>
                            )}
                          </h1>
                        </div>
                        {/* Match Score Section */}
                        <div className="flex flex-col items-center flex-grow-1 flex-shrink-0">
                          {/* Match Number */}
                          <h1 className="font-semibold text-xs sm:text-sm text-slate-400/50 text-center">
                            Match {parseInt(match?.match_no, 10) + 1}
                          </h1>

                          {/* Match Score */}
                          <div className="flex gap-x-1 sm:gap-x-2 justify-center items-center  mt-1 sm:mt-1">
                            <h1 className="text-xl sm:text-2xl font-medium">
                              {match?.teamA?.goals >= 0 ? match?.teamA?.goals : "-"}
                            </h1>
                            <h1 className="text-slate-400 font-medium">-</h1>
                            <h1 className="text-xl sm:text-2xl font-medium">
                              {match?.teamB?.goals >= 0 ? match?.teamB?.goals : "-"}
                            </h1>
                          </div>

                          {/* Full Time */}
                          {match?.complete && (
                            <div className="text-center text-sm sm:text-base font-medium text-green-500">
                              Full Time{match?.draw && " | Draw"}
                            </div>
                          )}

                          {/* Tiebreaker */}
                          {match?.draw && match?.tieBreaker?.breakerType && (
                            <div className="text-center text-sm sm:text-base font-medium text-orange-500">
                              {match.tieBreaker.teamA.goals > match.tieBreaker.teamB.goals
                                ? `${match.teamA.name} won by ${match.tieBreaker.breakerType} (${match.tieBreaker.teamA.goals} - ${match.tieBreaker.teamB.goals})`
                                : `${match.teamA.name} won by ${match.tieBreaker.breakerType} (${match.tieBreaker.teamB.goals} - ${match.tieBreaker.teamA.goals})`}
                            </div>
                          )}
                        </div>

                        <div className="flex gap-x-2 flex-col-reverse sm:flex-row sm:gap-x-4 items-center">
                          <h1 className="text-right text-sm sm:text-lg font-medium w-32 truncate">
                            {match?.teamB?.name ? (
                              match.teamB.name
                            ) : (
                              <span className="text-slate-500">Team B</span>
                            )}
                          </h1>
                          <img
                            src={
                              match?.teamB?.profile ||
                              "https://www.gstatic.com/onebox/sports/logos/crest_48dp.png"
                            }
                            className="w-12 h-12 sm:w-16 sm:h-16 rounded-full"
                            alt=".."
                          />
                        </div>
                      </div>
                      <hr className="transform rotate-90 my-auto w-12 hidden sm:block sm:w-16 border-gray-300" />
                      <div className="flex flex-col items-center text-xs sm:text-sm">
                        <div>12 Jun 2024</div>
                        {/* <div>12:00 PM</div> */}
                        <div className="flex items-center gap-1">
                          <MdOutlineLocationOn />
                          <div className="whitespace-nowrap">Kalyana Nagar</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
      </div>
      <MatchData
        openState={showMatchData?.show}
        data={showMatchData}
        isHost={data?.isHost}
        tournamentId={data._id}
        close={() => {
          setShowMatchData((prvs) => ({
            ...prvs,
            match: {},
            round: {},
            show: false,
          }));
        }}
      />
      {/* <TeamFlow/> */}
    </div>
  );
}

MatchTab.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MatchTab;
