import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/website_logo.png";

function Footer() {
  return (
    <div className="">
      <div className="max-w-[1500px] mx-auto">
        <div className="flex flex-col  items-center justify-between text-center p-8 gap-4 my-6 w-3/4 mx-auto">
          <div>
            <img src={Logo} alt="" />
          </div>
          <div className="text-lg">
            The connection between fans and clubs in the Championship is deeply
            rooted, creating a unique atmosphere that resonates with the essence
            of the beautiful game.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
