import React from "react";

import AllTransactions from "../../components/Admin/Transactions/AllTransactions";

function Transactions() {
  return (
    <div>
      <AllTransactions />
    </div>
  );
}

export default Transactions;
