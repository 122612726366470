/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState } from "react";
import { useFormik } from "formik";
import PropTypes from "prop-types";

import InputFields, {
  CssTextField,
  InputSubmit,
  SelectField,
} from "../InputFields/InputFields";
import cameraIcon from "../../assets/icons/camera.svg";
import playerSchema from "../../schema/user/playerSchema";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";
import CenterPopup from "../Popup/CenterPopUp";

function PlayerForm({ onClose, openState, data, profile, reRender }) {
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const [file, setFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(profile);
  const axios = useAxiosPrivate();
  const docRef = useRef();
  const [doc, setDoc] = useState(null);
  const [showDocErr, setShowDocErr] = useState(false);

  const handleUploadImage = (e) => {
    const image = e.target.files[0];
    if (image) {
      setFile(image);
      setPhotoURL(URL.createObjectURL(image));
    }
  };

  const handleUploadDoc = (e) => {
    const gDoc = e.target.files[0];
    if (gDoc) {
      setShowDocErr(false);
      setDoc(gDoc);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...data,
      age_category: "",
      blood_group: "",
      email: "",
      phone_number: "",
    },

    validationSchema: playerSchema,

    onSubmit: (values, { resetForm }) => {
      if (!doc) {
        setShowDocErr(true);
      } else {
        setLoading(true);
        const formData = new FormData();
        if (file) {
          formData.append("profile", file);
        }
        formData.append("doc", doc);

        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });

        axios
          .post("/user/player", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            reRender();
            useSuccessToast({ message: "New player added successfully" });
            resetForm({ values: "" });
            setPhotoURL("");
            setDoc(null);
            setFile(null);
            onClose();
          })
          .catch((err) => {
            const largErr = err?.response?.data?.error?.message;
            if (largErr && largErr.split(".")[0] === "File size too large")
              useErrorToast({ message: "Image size is too large" });
            else
              useErrorToast({
                message: err?.response?.data?.message || "Something went wrong",
              });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  return (
    <CenterPopup
      title="Add a player"
      openState={openState}
      close={() => onClose(false)}
    >
      <div className="w-full">
        <form className="box-border">
          <div className="flex flex-col items-center mb-4">
            <div className="relative w-32 h-32">
              <input
                type="file"
                name="profile"
                ref={ref}
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleUploadImage}
              />
              <button
                type="button"
                onClick={() => {
                  ref.current.click();
                }}
                className="relative w-full h-full border-2 rounded-full"
              >
                <div
                  className="relative w-full h-full rounded-full flex justify-center items-center"
                  style={{
                    backgroundImage: `url('${photoURL}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                >
                  {!photoURL && (
                    <img
                      src={cameraIcon}
                      alt=""
                      className="absolute top-0 bottom-0 mx-auto my-auto left-0 right-0"
                    />
                  )}
                </div>
              </button>
            </div>
          </div>

          <div className="flex flex-col items-center mb-4">
            <input
              type="file"
              name="document"
              ref={docRef}
              accept="application/pdf"
              style={{ display: "none" }}
              onChange={handleUploadDoc}
            />
            <button
              type="button"
              onClick={() => {
                docRef.current.click();
              }}
              className={`border ${
                showDocErr
                  ? "border-red-500 text-red-500"
                  : "border-slate-200 text-slate-500"
              } bg-slate-200 px-3 py-2 relative w-full max-w-md h-20 flex flex-col justify-center items-center rounded-md`}
            >
              Upload Aadhar
              {doc?.name && (
                <div className="h-5 mt-2">
                  <div className="text-black">{doc?.name}</div>
                </div>
              )}
            </button>
            {showDocErr && (
              <p className="text-red-700 mt-2">Document is required</p>
            )}
          </div>

          <div className="mb-4">
            <CssTextField
              error={formik.errors.name && formik.touched.name}
              sx={{ marginTop: "12px" }}
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                formik.errors.name && formik.touched.name
                  ? formik.errors.name
                  : ""
              }
              label="Name"
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <InputFields
              className="h-12 w-full"
              type="date"
              holder="Date of birth"
              name="date_of_birth"
              max={new Date().toISOString().split("T")[0]}
              min="1953-01-01"
              value={formik.values.date_of_birth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={
                formik.errors.date_of_birth && formik.touched.date_of_birth
                  ? formik.errors.date_of_birth
                  : ""
              }
            />
          </div>

          <div className="flex justify-between space-x-4">
            <div className="w-1/2">
              <SelectField
                name="ageCategory"
                value={formik.values.ageCategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  { value: "U-15", label: "U-15" },
                  { value: "U-17", label: "U-17" },
                  { value: "U-19", label: "U-19" },
                  { value: "Senior", label: "Senior" },
                ]}
                label="Age Category"
                error={
                  formik.errors.ageCategory && formik.touched.ageCategory
                }
                errorMsg={
                  formik.errors.ageCategory && formik.touched.ageCategory
                    ? formik.errors.ageCategory
                    : ""
                }
              />
            </div>
            <div className="w-1/2">
            <SelectField
                name="bloodGroup"
                value={formik.values.bloodGroup}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                options={[
                  { value: "A+", label: "A+" },
                  { value: "O+", label: "O+" },
                ]}
                label="Blood Group"
                error={
                  formik.errors.bloodGroup && formik.touched.bloodGroup
                }
                errorMsg={
                  formik.errors.bloodGroup && formik.touched.bloodGroup
                    ? formik.errors.bloodGroup
                    : ""
                }
              />
            </div>
          </div>

          <div className="mb-4">
            <CssTextField
              error={formik.errors.email && formik.touched.email}
              sx={{ marginTop: "12px" }}
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : ""
              }
              label="Email"
              className="w-full"
            />
          </div>

          <div className="mb-4">
            <CssTextField
              error={formik.errors.phone_number && formik.touched.phone_number}
              sx={{ marginTop: "12px" }}
              name="phone_number"
              type="tel"
              value={formik.values.phone_number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              helperText={
                formik.errors.phone_number && formik.touched.phone_number
                  ? formik.errors.phone_number
                  : ""
              }
              label="Phone Number"
              className="w-full"
            />
          </div>

          <div className="w-full flex justify-end mt-4">
            <InputSubmit
              className="w-32"
              loadingValue={loading ? "Loading..." : ""}
              value="Add"
              onClick={formik.handleSubmit}
            />
          </div>
        </form>
      </div>
    </CenterPopup>
  );
}

PlayerForm.defaultProps = {
  data: {
    name: "",
    date_of_birth: "",
  },
  profile: "",
  reRender: () => {},
};

PlayerForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
  profile: PropTypes.string,
  reRender: PropTypes.func,
  openState: PropTypes.bool.isRequired,
};

export default PlayerForm;

