/* eslint-disable no-undef */
import React from "react";
import PropTypes from "prop-types";
import { CssTextField, SelectField } from "../InputFields/InputFields";
import InputFields from "../InputFields/InputFields";
import { MenuItem, FormControl, InputLabel, Select } from "@mui/material";

function StaffDetails({ values, handleChange, handleBlur, errors, touched, isEdit }) {
  return (
    <div className="bg-white border shadow-2xl rounded-lg p-6 sm:p-10">
      <div className="w-full sm:w-[80vw] max-w-[700px] flex flex-col gap-y-2 box-border mx-auto">
        <div className="text-2xl font-medium">Staff Details</div>

        <div className="flex flex-col sm:flex-row gap-4 mb-1 mt-6 w-full">
          <CssTextField
            error={errors.name && touched.name}
            sx={{ marginTop: "8px" }}
            label="Staff Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.name && touched.name ? errors.name : ""}
            fullWidth
          />
          <div className="flex flex-col w-full">
            <p className="text-gray-700 sm:-mt-3 text-sm">Date of Birth</p>
            <InputFields
              name="dob"
              className="h-14"
              holder="DOB"
              value={values.dob}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={errors.dob && touched.dob ? errors.dob : ""}
              type="date"
              fullWidth

            />
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-4">
          <CssTextField
            error={errors.email && touched.email}
            sx={{ marginTop: "8px" }}
            name="email"
            label="Email Id"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.email && touched.email ? errors.email : ""}
            fullWidth
            disabled={isEdit}
          />
          <CssTextField
            error={errors.phone && touched.phone}
            sx={{ marginTop: "8px" }}
            name="phone"
            label="Contact No"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.phone && touched.phone ? errors.phone : ""}
            fullWidth

          />
        </div>

        {/* Address Fields */}
        <div className="flex flex-col sm:flex-row gap-4">
          <CssTextField
            error={errors.address1 && touched.address1}
            sx={{ marginTop: "8px" }}
            name="address1"
            label="Address 1"
            value={values.address1}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errors.address1 && touched.address1 ? errors.address1 : ""
            }
            fullWidth
          />
          <CssTextField
            error={errors.address2 && touched.address2}
            sx={{ marginTop: "8px" }}
            name="address2"
            label="Address 2"
            value={values.address2}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errors.address2 && touched.address2 ? errors.address2 : ""
            }
            fullWidth
          />
        </div>

        {/* City, State, Country */}
        <div className="flex flex-col sm:flex-row gap-4">
          <CssTextField
            error={errors.city && touched.city}
            sx={{ marginTop: "8px" }}
            name="city"
            label="City"
            value={values.city}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.city && touched.city ? errors.city : ""}
            fullWidth
          />
          <CssTextField
            error={errors.state && touched.state}
            sx={{ marginTop: "8px" }}
            name="state"
            label="State"
            value={values.state}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.state && touched.state ? errors.state : ""}
            fullWidth
          />
        </div>

        <div className="flex flex-col sm:flex-row gap-4">
          <CssTextField
            error={errors.country && touched.country}
            sx={{ marginTop: "8px" }}
            name="country"
            label="Country"
            value={values.country}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.country && touched.country ? errors.country : ""}
            fullWidth
          />
          <CssTextField
            error={errors.pincode && touched.pincode}
            sx={{ marginTop: "8px" }}
            name="pincode"
            label="Pincode"
            value={values.pincode}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errors.pincode && touched.pincode ? errors.pincode : ""}
            fullWidth
          />
        </div>

        {/* Role Dropdown */}
        <div className="mt-10 text-xl font-medium">Staff Details</div>
        <div className="flex flex-col sm:flex-row gap-4 mb-1 mt-6 w-full">
          <SelectField
            name="role"
            value={values.role}
            onChange={handleChange}
            onBlur={handleBlur}
            options={[
              { value: "Coach", label: "Coach" },
              { value: "Manager", label: "Manager" },
              { value: "Trainer", label: "Trainer" },
            ]}
            label="Role"
            error={errors.role && touched.role}
            errorMsg={errors.role && touched.role ? errors.role : ""}
          />

          <div className="flex flex-col w-full">
            <p className="text-gray-700 sm:-mt-5 text-sm">Date of joining</p>
            <InputFields
              name="joining_date"
              className="h-14"
              holder="Joining Date"
              value={values.joining_date}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMsg={
                errors.joining_date && touched.joining_date
                  ? errors.joining_date
                  : ""
              }
              type="date"
              fullWidth
              disabled={isEdit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

StaffDetails.propTypes = {
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
};

export default StaffDetails;
