import React, { useState } from "react";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";
import closeIcon from "../../assets/icons/close.svg"; // Path to close icon

function Modal({ open, closeModal, title, children, showWarning }) {
  const [showConfirm, setShowConfirm] = useState(false);

  const handleClose = () => {
    if (showWarning) {
      setShowConfirm(true);
    } else {
      closeModal();
    }
  };

  const handleConfirmClose = () => {
    setShowConfirm(false);
    closeModal();
  };

  const handleCancelClose = () => {
    setShowConfirm(false);
  };

  if (!open) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
      <div className="relative bg-white border rounded-lg shadow-lg w-full sm:w-4/5 md:w-1/2 max-w-4xl p-4">
        {showConfirm ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-90 rounded-lg p-4">
            <div className="text-center">
              <h2 className="text-lg mb-4">Are you sure?</h2>
              <div className="flex justify-center gap-4">
                <button
                  type="button"
                  className="border border-red-600 text-red-600 rounded px-4 py-2"
                  onClick={handleCancelClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="bg-gradient-to-r from-primary-start to-primary-end text-white rounded px-4 py-2"
                  onClick={handleConfirmClose}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold">{title}</h2>
              <MdClose className="w-6 h-8 cursor-pointer" onClick={closeModal} />
            </div>
            <div className="mt-4 overflow-auto">{children}</div>
          </>
        )}
      </div>
    </div>
  );
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showWarning: PropTypes.bool,
};

Modal.defaultProps = {
  showWarning: false,
};

export default Modal;
