import React from "react";

import AllClubs from "../../components/Admin/Clubs/AllClubs";

function Clubs() {
  return (
    <div>
      <AllClubs />
    </div>
  );
}

export default Clubs;
