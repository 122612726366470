import React from "react";
import { Link } from "react-router-dom";

import heroImg from "../../assets/img/hero-img.jpg";

function Hero() {
  return (
    <div className="max-h-max my-16 sm:max-h-[500px] flex flex-col sm:gap-10 sm:flex-row justify-between mx-auto max-w-[1500px]">
      <div className="w-full h-full sm:w-1/2 flex flex-col justify-center self-start sm:self-center order-2 sm:order-1 px-10 ">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-black text-[#013581]">
          Elevate Your Football Experience
        </h1>
        <p className="text-base md:text-lg lg:text-xl font-normal my-3">
          Join the community of sports enthusiasts and take part in tournaments
          with ease.
        </p>
        <Link
          to="/user/club"
          className="bg-gradient-to-r from-primary-start to-primary-end w-fit px-4 py-2 text-sm md:text-base lg:text-lg font-semibold rounded text-white hover:bg-orange-700"
        >
          Get started
        </Link>
      </div>
      <div className="relative w-full sm:w-1/2 my-12 h-[250px] sm:h-[350px] flex justify-end items-center order-1 sm:order-2">
        <div className="left-0 bg-white border-y border-l shadow-2xl rounded-l-lg h-[400px] w-[20%] sm:w-[10%] -ml-10 sm:-ml-20 z-10 sm:block hidden"></div>
        <div
          className="relative sm:border-y sm:border-l sm:rounded-l-lg sm:shadow-2xl z-10 w-full h-[300px] sm:h-[500px] bg-center bg-no-repeat bg-contain"
          style={{ backgroundImage: `url('${heroImg}')` }}
        />
      </div>
    </div>
  );
}

export default Hero;
