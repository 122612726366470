/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputSubmit, InputTextArea } from "../InputFields/InputFields";
import Modal from "../Modal/Modal";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useErrorToast, useSuccessToast } from "../../hooks/useToast";

function StatusManage({
  close,
  status,
  name,
  text,
  id,
  successClose,
  comment,
  type,
}) {
  const [comments, setComments] = useState(comment);
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .patch(`/admin/${type}/update-status`, { status, id, comment: comments })
      .then((res) => {
        if (res?.data?.success) {
          useSuccessToast({ message: res?.data?.message });
          successClose({ comment: comments });
        } else {
          useErrorToast({
            message: res?.data?.message || "Something went wrong",
          });
        }
      })
      .catch((err) => {
        useErrorToast({
          message: err?.response?.data?.message || "Something went wrong",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal 
      open={true} 
      closeModal={close} 
      title={`${text} ${name}`}
      size="md"
    >
      <div className="p-4 md:p-6">
        <h1 className="text-lg font-semibold mb-4">
          Are you sure you want to {text} {name}?
        </h1>
        <InputTextArea
          className="mb-4 w-full"
          holder="Comments (optional)"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <div className="flex justify-end">
          <InputSubmit
            value="Update"
            loadingValue={loading ? "Updating..." : ""}
            onClick={handleSubmit}
            disabled={loading}
            className="w-full md:w-auto"
          />
        </div>
      </div>
    </Modal>
  );
}

StatusManage.propTypes = {
  close: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  successClose: PropTypes.func.isRequired,
  comment: PropTypes.string,
  type: PropTypes.string.isRequired,
};

StatusManage.defaultProps = {
  comment: "",
};

export default StatusManage;