import React from "react";

import AllUsers from "../../components/Admin/Users/AllUsers";

function Users() {
  return (
    <div>
      <AllUsers />
    </div>
  );
}

export default Users;
